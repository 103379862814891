/* global $*/
$(document).ready(function(){
    $(document).on('click','#sidebarToggle',function(){
        const app=$(this)
        if(app.hasClass('fa-bars')){
            $("#page_sidebar").removeClass('page_sidebar-disable');
            $("#sidebar_menu").slideDown();
            app.removeClass('fa-bars').addClass('fa-close')
        }else{
            $("#sidebar_menu").slideUp(function(){
                $("#page_sidebar").addClass('page_sidebar-disable');
                app.addClass('fa-bars').removeClass('fa-close')
            });

        }

    })

    $(document).on('click','.more-1line',function(){
        if($(this).find('i.fa-chevron-circle-up').length){
            $(this).find('i.fa-chevron-circle-up').removeClass('fa-chevron-circle-up').addClass('fa-chevron-circle-down');
            $(this).parent().find('.max-1line').addClass('less-1line').removeClass('max-1line')
            $(this).find('span').text($(this).attr('data-less'));
        }else{
            $(this).find('i.fa-chevron-circle-down').addClass('fa-chevron-circle-up').removeClass('fa-chevron-circle-down');
            $(this).parent().find('.less-1line').addClass('max-1line').removeClass('less-1line')
            $(this).find('span').text($(this).attr('data-more'));
        }
    })

})
